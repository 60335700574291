import {useCallback, useEffect} from "react";
import {ActiveAudio} from "../store.ts";
import posthog from "posthog-js";
import {APP_NAME} from "../common/constants.ts";

export const useMediaSession = (
  activeAudio: ActiveAudio,
  play: () => void,
  pause: () => void,
  stop: () => void,
  seek: (time: number) => void,
  seekBackward: (seconds: number) => void,
  seekForward: (seconds: number) => void
) => {
  const setMediaSessionHandlers = useCallback(() => {
      if ('mediaSession' in navigator) {
        if (activeAudio) {
          navigator.mediaSession.metadata = new MediaMetadata({
            title: activeAudio?.title,
            artist: activeAudio?.subtitle,
            album: APP_NAME,
            artwork: [
              {src: '/logo_bg.svg', sizes: '96x96', type: 'image/svg'}
            ],
          });
        }

        navigator.mediaSession.setActionHandler('play', () => {
          play();

          posthog.capture('Play audio', {
            source: 'ms',
            audioId: activeAudio.id
          });
        });

        navigator.mediaSession.setActionHandler('pause', () => {
          pause();

          posthog.capture('Pause audio', {
            source: 'ms',
            audioId: activeAudio.id
          });
        });

        navigator.mediaSession.setActionHandler('stop', () => {
          stop();

          posthog.capture('Stop playback', {
            source: 'ms',
            audioId: activeAudio.id
          });
        });

        navigator.mediaSession.setActionHandler('seekforward', (details) => {
          seekForward(details.seekOffset ?? 10);
          posthog.capture('10 sec forward', {
            source: 'ms',
            audioId: activeAudio.id
          });
        });

        navigator.mediaSession.setActionHandler('seekbackward', (details) => {
          seekBackward(details.seekOffset ?? 10);
          posthog.capture('10 sec backward', {
            source: 'ms',
            audioId: activeAudio.id
          });
        });

        navigator.mediaSession.setActionHandler('seekto', (details) => {
          if (details.seekTime) {
            seek(details.seekTime);
            posthog.capture('Seek audio', {
              source: 'ms',
              audioId: activeAudio.id
            });
          }
        });
      }
    }, [activeAudio, play, pause, stop, seekForward, seekBackward, seek]
  );

  const clearMediaSessionHandlers = useCallback(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.setActionHandler('play', null);
      navigator.mediaSession.setActionHandler('pause', null);
      navigator.mediaSession.setActionHandler('seekbackward', null);
      navigator.mediaSession.setActionHandler('seekforward', null);
      navigator.mediaSession.setActionHandler('seekto', null);
      navigator.mediaSession.setActionHandler('stop', null);
    }
  }, []);

  useEffect(() => {
    setMediaSessionHandlers();
    return clearMediaSessionHandlers;
  }, [setMediaSessionHandlers, clearMediaSessionHandlers]);
};