import {FC} from "react";
import {Button} from "@nextui-org/react";
import {UserPanel} from "./user-panel.tsx";
import {ChevronLeft24Filled} from "@fluentui/react-icons";
import {Link} from "react-router-dom";
import {useAtomValue} from "jotai";
import {appHeaderAtom} from "../store.ts";
import {APP_NAME} from "../common/constants.ts";

const BackButton: FC<{backButtonVisible: boolean}> = ({backButtonVisible}) => {
  if(!backButtonVisible) {
    return null;
  }

  return <Button isIconOnly variant={"light"} as={Link} to="/apps" disableRipple>
    <ChevronLeft24Filled className="font-bold"/>
  </Button>
}

const AppHeader: FC = () => {
  const {title, backButtonVisible} = useAtomValue(appHeaderAtom);
  return <div className="flex justify-between px-4 py-5 items-center">
    <div className="flex items-center gap-2">
      <BackButton backButtonVisible={backButtonVisible} />
      <h1 className="text-3xl pb-0.5">{title}</h1>
    </div>
    <UserPanel/>
  </div>
}


const TwoPaneAppHeader: FC = () => {
  return <div className="flex justify-between px-4 py-5 items-center">
    <div className="flex items-center gap-2">
      <h1 className="text-2xl pb-0.5">{APP_NAME}</h1>
    </div>
    <UserPanel/>
  </div>
}




export {AppHeader, TwoPaneAppHeader};