import {useAtomValue, useSetAtom} from "jotai";
import {appHeaderAtom, isAuthenticatedAtom, userIdAtom} from "../store.ts";
import {AppItem} from "../components/app-item.tsx";
import {AppItemSkeleton} from "../components/app-item-skeleton.tsx";
import {useCallback, useLayoutEffect} from "react";
import posthog from "posthog-js";
import {useApps} from "../hooks/use-apps.ts";
import {APP_NAME} from "../common/constants.ts";

export function InstalledApps() {
  const isAuthenticated = useAtomValue(isAuthenticatedAtom);
  const userId = useAtomValue(userIdAtom);
  const updateHeader = useSetAtom(appHeaderAtom);

  useLayoutEffect(() => {
    updateHeader({
      title: APP_NAME,
      backButtonVisible: false
    });
  }, []);

  const apps = useApps(isAuthenticated, userId);

  const onAppSelected = useCallback(({title, handle}: { title: string, handle: string }) => {
    posthog.capture('App item clicked', {
      app: handle
    });

    updateHeader({
      title: title,
      backButtonVisible: true,
    })
  }, []);

  const renderChannels = () => apps!.map(app => <AppItem key={app.handle}
                                                         handle={app.handle}
                                                         title={app.title}
                                                         logoUrl={app.logoUrl}
                                                         blur={false}
                                                         onAppSelected={onAppSelected}/>);

  const renderSkeletons = () => Array.from(Array(3).keys()).map(i => <AppItemSkeleton key={i}/>);

  return <div className="flex-1">
    {apps ? renderChannels() : renderSkeletons()}
    {!isAuthenticated && (<AppItem title="Sense" handle="sense" blur={true}/>)}
  </div>
}