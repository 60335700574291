import {FC, useMemo, useState} from "react";

import qyLogo from '../assets/qy.svg';
import googleLogo from '../assets/google.svg';
import {Trans, msg} from "@lingui/macro";
import {useLingui} from "@lingui/react";
import {useAuth} from "../hooks/firebase.ts";
import {EMAILSENT_KEY} from "../common/constants.ts";
import {GoogleAuthProvider, sendSignInLinkToEmail, signInWithPopup} from "firebase/auth";
import {captureException} from "@sentry/react";
import {useNavigate} from "react-router-dom";
import posthog from "posthog-js";

const validateEmail = (value: string) =>
    value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);

const SignIn: FC = () => {
    const {_} = useLingui();
    const navigate = useNavigate();
    const auth = useAuth();
    const [email, setEmail] = useState(
        window.localStorage.getItem(EMAILSENT_KEY) || "",
    );
    const [emailSent, setEmailSent] = useState(
        window.localStorage.getItem(EMAILSENT_KEY) !== null,
    );
    const [shouldValidate, setShouldValidate] = useState(false);

    const isInvalid = useMemo(() => {
        if (email === "" || !shouldValidate) return false;
        return !validateEmail(email);
    }, [email, shouldValidate]);

    const signInWithGoogle = async () => {
        try {
            const userCredential = await signInWithPopup(
                auth,
                new GoogleAuthProvider(),
            );
            posthog.identify(userCredential.user.uid);
            posthog.capture("Login", {
                method: "Google",
            });
            navigate('/');
        } catch (e) {
            captureException(e);
            posthog.capture("Login failed", {
                method: "Google",
            });
        }
    };
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isInvalid) return;

        window.localStorage.setItem(EMAILSENT_KEY, email);
        await sendSignInLinkToEmail(auth, email, {
            handleCodeInApp: true,
            url: window.location.origin,
        });

        posthog.capture("Login email sent");
        setShouldValidate(false);
        setEmailSent(true);
    };

    const resetEmail = () => {
        setEmailSent(false);
        setShouldValidate(false);
        setEmail("");
        window.localStorage.removeItem(EMAILSENT_KEY);
        posthog.capture("Login email reset");
    };

    const renderEmailForm = () => <>
        <h2 className="text-xl mb-8"><Trans>Enter your email below to continue to QuickYou</Trans></h2>

        <form onSubmit={handleSubmit} noValidate className="flex flex-col gap-4">
            <input className="border-2 p-4 rounded-2xl" type="email" placeholder={_(msg`Enter email`)}
                   value={email}
                   onChange={(e) => {
                       setEmail(e.target.value)
                       setShouldValidate(true)
                   }}
            />
            <button
                disabled={isInvalid}
                className="cursor-pointer bg-amber-500 text-neutral-100 font-medium rounded-2xl py-4 shadow-lg active:scale-95 transition-transform disabled:scale-100 disabled:shadow-none disabled:bg-neutral-400"
                type="submit"
            ><Trans>Continue</Trans></button>
        </form>
    </>;

    const renderEmailSent = () => <>
        <h2 className="text-xl mb-8"><Trans>Email was sent to <span className="font-medium">{email}</span>. Please open it and follow the link.</Trans></h2>

        <button onClick={resetEmail}
                className="border-2 border-solid font-medium rounded-2xl py-4 active:scale-95 transition-transform">
            <Trans>Change email</Trans>
        </button>
    </>;

    return <div
        className="flex flex-col justify-between sm:justify-center sm:gap-y-8 h-svh p-6 select-none sm:max-w-[55ch] mx-auto">
        <div className="flex flex-col sm:rounded-2xl sm:p-6 sm:bg-neutral-50">
            <img draggable="false" width="96" height="96" src={qyLogo} alt={_(msg`QuickYou logo`)}
                 className="w-24 bg-neutral-300 shadow-lg rounded-full mb-8"/>

            <h1 className="text-4xl font-medium mb-4"><Trans>Account Setup</Trans></h1>

            {emailSent ? renderEmailSent() : renderEmailForm()}

            <div className="flex items-center gap-4 py-8">
                <hr className="bg-neutral-100 border-none w-full h-0.5 flex-1"/>
                <p className="uppercase text-xs"><Trans>or</Trans></p>
                <hr className="bg-neutral-100 border-none w-full h-0.5 flex-1"/>
            </div>

            <button onClick={signInWithGoogle}
                    className="cursor-pointer font-medium rounded-2xl p-4 flex justify-between border-2 border-solid active:scale-95 transition-transform"
            >
                <img draggable="false" width="24" height="24" className="inline-block" src={googleLogo}
                     alt={_(msg`Google logo`)}
                />
                <span className="flex-1 text-center"><Trans>Continue with Google</Trans></span>
            </button>
        </div>

        <div className="flex justify-end sm:justify-center gap-6 text-sm ">
            <a href="https://www.quick-you.com/privacy-policy"
               target="_blank"
               className="decoration-amber-500/60 decoration-2 underline underline-offset-4"><Trans>Privacy</Trans></a>
            <a href="https://www.quick-you.com/terms"
               target="_blank"
               className="decoration-amber-500/60 decoration-2 underline underline-offset-4"><Trans>Terms</Trans></a>
        </div>
    </div>
}

export {
    SignIn
};