import {FC} from "react";
import {Avatar, Button, Popover, PopoverContent, PopoverTrigger, Radio, RadioGroup} from "@nextui-org/react";
import {SignOut20Regular} from "@fluentui/react-icons";
import {Link, useNavigate} from "react-router-dom";
import {Trans} from "@lingui/macro";
import {audioSpeedAtom, isAuthenticatedAtom, userLanguageAtom, userProfileAtom} from "../store.ts";
import {useAtom, useAtomValue} from "jotai";
import posthog from "posthog-js";
import {getAuth, signOut} from "firebase/auth";
import {playbackRates} from "../common/constants.ts";

const UserPanel: FC = () => {
  const navigate = useNavigate();

  const isAuthenticated = useAtomValue(isAuthenticatedAtom);
  const userProfile = useAtomValue(userProfileAtom);
  const [userLanguage, setUserLanguage] = useAtom(userLanguageAtom);
  const [audioSpeed, setAudioSpeed] = useAtom(audioSpeedAtom);

  const signOutClick = async () => {
    await signOut(getAuth());
    posthog.reset();
    navigate('/');
  }

  const changeLanguage = async (value: string) => {
    await setUserLanguage(value);
    posthog.capture('Content language changed', {
      language: value
    });
  };

  const changeAudioSpeed = async (value: string) => {
    await setAudioSpeed(+value);
    posthog.capture('Player speed changed', {
      audioSpeed: +value
    });
  };

  const renderAuthenticated = () => {
    if (userProfile == null) {
      return null;
    }

    return <div>
      <Popover placement="top-end" backdrop="blur" showArrow offset={20}>
        <PopoverTrigger>
          <Avatar isBordered radius="full" src={userProfile.avatar ?? ''!}/>
        </PopoverTrigger>
        <PopoverContent className="w-[240px]">
          <div className="px-1 py-2 w-full">
            <p><Trans>Logged in as</Trans></p>
            <p className="font-semibold truncate">{userProfile.email}</p>
            <div>
              <h3 className="font-semibold"><Trans>Select content language</Trans></h3>
              <p className="mb-2 text-sm text-foreground/50">
                <Trans>Language content will be processed in</Trans>
              </p>
              <RadioGroup value={userLanguage} onValueChange={changeLanguage}>
                <Radio value="en"><Trans>English</Trans></Radio>
                <Radio value="uk"><Trans>Ukrainian</Trans></Radio>
                <Radio value="ro"><Trans>Romanian</Trans></Radio>
                <Radio value="pl"><Trans>Polish</Trans></Radio>
              </RadioGroup>
            </div>
            <div className="mt-4">
              <h3 className="font-semibold"><Trans>Select player speed</Trans></h3>
              <p className="mb-2 text-sm text-foreground/50">
                <Trans>Applied to all audio files played</Trans>
              </p>
              <RadioGroup value={audioSpeed?.toString()} onValueChange={changeAudioSpeed}>
                {playbackRates.map(r => <Radio key={r.value} value={r.value.toString()}>{r.label}</Radio>)}
              </RadioGroup>
            </div>
            <Button variant="light" className="w-full mt-2" onClick={signOutClick} disableRipple>
              <SignOut20Regular/>
              <Trans>Sign out</Trans>
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  }

  const renderNoUser = () => {
    return <div>
      <Button variant="light" as={Link} to="/sign-in" className="font-semibold">
        <Trans>Sign In</Trans>
      </Button>
    </div>
  }

  return isAuthenticated ? renderAuthenticated() : renderNoUser();
}

export {UserPanel};