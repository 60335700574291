import {atom} from "jotai";
import {FirebaseApp} from "firebase/app";
import {doc, getFirestore, setDoc} from "firebase/firestore";
import {captureException} from "@sentry/react";
import {atomWithReset} from 'jotai/utils'

export const firebaseAtom = atom<FirebaseApp | null>(null)

export interface UserProfile {
  userId: string;
  email: string;
  displayName: string | undefined;
  avatar: string | null;
  language: string;
  audioSpeed: number;
  installedAppIds: Array<string>;
}

export interface AppHeader {
  title: string,
  backButtonVisible: boolean;
}

export const isAuthenticatedAtom = atom(false);
export const userProfileAtom = atom<UserProfile | null>(null);
export const activeInstanceIdAtom = atom<string | undefined>(undefined);
export const userIdAtom = atom((get) => get(userProfileAtom)?.userId);
export const userLanguageAtom = atom((get) => get(userProfileAtom)?.language,
  async (get, set, args) => {
    set(userProfileAtom, {...get(userProfileAtom)!, language: args as string});

    await setDoc(doc(getFirestore(), 'users', get(userProfileAtom)!.userId), {language: args}, {merge: true})
      .catch(captureException);
  }
)

export const audioSpeedAtom = atom((get) =>
    get(userProfileAtom)?.audioSpeed,
  async (get, set, args) => {
    set(userProfileAtom, {...get(userProfileAtom)!, audioSpeed: args as number});

    await setDoc(doc(getFirestore(), 'users', get(userProfileAtom)!.userId), {audioSpeed: args}, {merge: true})
      .catch(captureException);
  }
)

export const appHeaderAtom = atom<AppHeader>({
  title: '',
  backButtonVisible: false
})

export const isPlayerVisibleAtom = atom(false);

export interface ActiveAudio {
  id: string;
  title?: string,
  subtitle?: string,
  audioUrl: string;
  audioDuration: number;
}

export const activeAudioAtom = atomWithReset<ActiveAudio | null>(null);
export const activeContentIdAtom = atom((get) => get(activeAudioAtom)?.id);
