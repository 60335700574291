import {Pause24Filled, Play24Filled,} from "@fluentui/react-icons";

interface PlayPauseIconProps {
  isPlaying: boolean
}

function PlayPauseIcon({isPlaying}: PlayPauseIconProps) {
  return isPlaying ? <Pause24Filled/> : <Play24Filled/>;
}

export {PlayPauseIcon}