import {Button} from "@nextui-org/react";
import {useEffect, useState} from "react";
import {useAtom} from "jotai/index";
import {audioSpeedAtom} from "../../store.ts";
import {playbackRates} from "../../common/constants.ts";

export function PlaybackSpeedButton() {
  const [audioSpeed, setAudioSpeed] = useAtom(audioSpeedAtom);
  const [currentRate, setCurrentRate] = useState(playbackRates.findIndex(r => r.value === audioSpeed));

  useEffect(() => {
    setCurrentRate(playbackRates.findIndex(r => r.value === audioSpeed)!);
  }, [audioSpeed]);

  const updateRate = async () => {
    const rateIndex = (currentRate + 1) % playbackRates.length; // Cycle through the rates
    setCurrentRate(rateIndex)
    await setAudioSpeed(playbackRates[rateIndex].value);
  }

  return <Button
    className="font-semibold"
    radius="full"
    isIconOnly
    size="md"
    variant="light"
    onPress={updateRate}
  >
    {playbackRates[currentRate]?.label}
  </Button>
}