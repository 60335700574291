import {useEffect, useState} from "react";
import {getAuth, User} from "firebase/auth";
import {getFirestore} from "firebase/firestore";

export const useAuth = () => getAuth();
export const useFirestore = () => getFirestore();

export const useUser = () => {
  const auth = useAuth();
  const [user, setUser] = useState<User | null>(auth.currentUser);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      setUser(firebaseUser);
    });
    return () => unsubscribe();
  }, [auth]);

  return {user, isAuthenticated: user !== null};
};

