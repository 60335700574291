import useSWRSubscription from "swr/subscription";
import {Content} from "../lib/types.ts";
import {collection, getFirestore, onSnapshot, orderBy, query, Query} from "firebase/firestore";
import {useMemo} from "react";
import {format} from "date-fns";

export const useAppContent = (isAuthenticated: boolean, handle: string, userId?: string) => {
  const {data} = useSWRSubscription<Content[], Error, Array<string | undefined>>(['content', userId, handle!],
    ([, userId, handle], {next}) => {
      if (isAuthenticated && !userId) {
        // do nothing if authenticated and users id is not established yet
        return () => {
        };
      }

      const q: Query = query(collection(getFirestore(), `instances/${handle}/content`),
        orderBy('timestamp'))

      const unsubscribe = onSnapshot(q, (snapshot) => {
        next(null, snapshot.docs.map(d => ({id: d.id, ...d.data()} as Content)));
      })
      return () => unsubscribe();
    })

  return useMemo(() => {
    const emptyMap = new Map<string, Content[]>();
    return data?.reduce((previousValue: Map<string, Content[]>, currentValue) => {
      const key = format(currentValue.timestamp.toMillis(), 'P');
      if (previousValue.has(key)) {
        previousValue.get(key)!.push(currentValue);
      } else {
        previousValue.set(key, [currentValue]);
      }
      return previousValue;
    }, emptyMap) ?? undefined;
  }, [data]);
}