import useSWRSubscription from "swr/subscription";
import {AppInstallation} from "../lib/types.ts";
import {collection, documentId, getFirestore, onSnapshot, Query, query, where} from "firebase/firestore";
import {useAtomValue} from "jotai";
import {userProfileAtom} from "../store.ts";


export const useApps = (isAuthenticated: boolean, userId?: string) => {
  const profile = useAtomValue(userProfileAtom);
  const {data} = useSWRSubscription<AppInstallation[], Error, Array<string | undefined>>(['apps', userId, profile?.installedAppIds?.length.toString()],
    ([, userId], {next}) => {
      if (isAuthenticated && !userId) {
        // do nothing if authenticated and users id is not established yet
        return () => {
        };
      }

      let q: Query;
      if (isAuthenticated && profile?.installedAppIds?.length && profile?.installedAppIds?.length > 0) {
        q = query(collection(getFirestore(), 'instances'),
          where(documentId(), 'in', profile?.installedAppIds));
      } else {
        q = query(collection(getFirestore(), 'instances'),
          where('isForEveryone', '==', true));
      }
      const unsubscribe = onSnapshot(q, (snapshot) => {
        next(null, snapshot.docs.map(d => ({
          handle: d.data().handle,
          title: d.data().title,
          logoUrl: d.data().logoUrl,
          interactive: d.data().interactive
        } as AppInstallation)));
      })
      return () => unsubscribe();
    });
  return data;
}