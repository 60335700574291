import {AppHeader, TwoPaneAppHeader} from "./app-header.tsx";
import {Outlet} from "react-router-dom";
import {useAtomValue} from "jotai";
import {appHeaderAtom, isPlayerVisibleAtom} from "../store.ts";
import {Player} from "./player/player.tsx";
import {AnimatePresence, motion} from "framer-motion";
import {InstalledApps} from "../routes/installed-apps.tsx";
import {APP_NAME} from "../common/constants.ts";

const playerVariants = {
  hidden: {opacity: 0, y: 100},
  visible: {opacity: 1, y: 0},
  exit: {opacity: 0, y: 100},
};

const PlayerContainer = () => {
  const isPlayerOpen = useAtomValue(isPlayerVisibleAtom);

  return <AnimatePresence mode="popLayout">
    {isPlayerOpen ?
      <motion.div
        variants={playerVariants}
        initial="hidden"
        animate="visible"
        exit="exit">
        <Player/>
      </motion.div> : null}
  </AnimatePresence>;
}

export function AppLayout() {
  return <>
    <AppHeader></AppHeader>
    <Outlet></Outlet>
    <PlayerContainer/>
  </>
}

export function TwoPaneAppLayout() {
  const {title} = useAtomValue(appHeaderAtom);
  return <div className="flex h-full">
    <div className="w-80 flex flex-col">
      <TwoPaneAppHeader/>
      <InstalledApps/>
      <PlayerContainer/>
    </div>
    <div className="flex flex-col flex-1 overflow-hidden">
      {title != APP_NAME &&
          <div className="px-4 py-6 text-2xl">{title}</div>
      }
      <Outlet></Outlet>
    </div>
  </div>
}