import {Button, ButtonProps} from "@nextui-org/react";
import React from "react";

export interface PlayerButtonProps extends ButtonProps {
    icon: React.ReactNode
}

const PlayerButton = React.forwardRef<HTMLButtonElement, PlayerButtonProps>(
    ({icon, className, ...props}, ref) => {
        return (
            <Button
                radius="full"
                isIconOnly
                size="md"
                className={className}
                ref={ref}
                variant="light"
                {...props}
            >
                {icon}
            </Button>
        )
    }
)
PlayerButton.displayName = "PlayerButton"

export {PlayerButton}