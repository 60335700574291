import {FC, ReactNode} from "react";
import {AppLogo} from "./app-logo.tsx";
import {NavLink} from "react-router-dom";
import {clsx} from "clsx";
import {useLingui} from "@lingui/react";
import {msg} from "@lingui/macro";
import {nop} from "../common/constants.ts";

type AppItemProps = {
  title: string;
  logoUrl?: string
  handle: string;
  blur?: boolean
  onAppSelected?: (app: { title: string, handle: string }) => void
}

const AppItem: FC<AppItemProps> = ({title, logoUrl, handle, blur = true, onAppSelected}) => {
  const {_} = useLingui()
  const wrapper = (node: ReactNode) => {
    const classes = 'py-2 px-2 mx-2 gap-3 flex items-center mb-2'
    if (!blur) {
      return <NavLink to={`/apps/${handle}`} className={({isActive}) => clsx(classes, 'cursor-pointer', {
        'bg-neutral-100 rounded-2xl': isActive,
      })} onClick={() => onAppSelected ? onAppSelected({title, handle}) : nop()}>{node}</NavLink>
    } else {
      return <div className={clsx(classes, 'cursor-not-allowed blur-sm')}
                  title={_(msg`Account needed for this app to work.`)}>{node}</div>
    }
  }

  return wrapper(<>
      <div>
        <AppLogo src={logoUrl}/>
      </div>
      <div className="flex-1">
        <p className="text-xl font-medium truncate">{title}</p>
      </div>
    </>
  );
}

export {AppItem};