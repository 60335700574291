import {useEffect, useRef} from 'react';

/**
 * Custom hook to scroll a div to the bottom when new items are added.
 *
 * @param {any[]} dependencyList - Array of dependencies to trigger scroll on change.
 */
export const useScrollToBottom = (dependencyList: unknown) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [dependencyList]);

  return scrollRef;
};