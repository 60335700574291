import {FC} from "react";
import {
  CardContent,
  Content,
  ErrorContent,
  InProgressContent,
  ProcessedCard,
  TextContent,
  UserContent
} from "../lib/types.ts";
import {captureMessage} from "@sentry/react";
import {clsx} from "clsx";
import {msg, Trans} from "@lingui/macro";
import {useSetAtom} from "jotai/index";
import {activeAudioAtom, activeContentIdAtom, isPlayerVisibleAtom} from "../store.ts";
import {Button, Card, CardBody, CardFooter, Image} from "@nextui-org/react";
import {formatTime} from "../common/utils.ts";
import {useAtomValue} from "jotai";
import CyclingLoader from "./cycling-loader.tsx";
import {doc, getFirestore, setDoc} from "firebase/firestore";
import {useLingui} from "@lingui/react";

export const AppContent: FC<{ content: Content }> = ({content}) => {
  if (content.type === 'user') {
    return <UserMessage content={content}/>;
  }
  if (content.type === 'error') {
    return <ServerErrorMessage content={content}/>;
  }

  if (content.type === 'text' || (content.type === 'in-progress' && content.content?.type === 'text')) {
    return <ServerTextMessage content={content}/>;
  }

  if (content.type === 'card' || (content.type === 'in-progress' && content.content?.type === 'card')) {
    return <ServerCardMessage content={content}/>;
  }

  captureMessage('Unknown content', {
    level: 'error',
    extra: {...content}
  });

  return null;
};

const UserMessage: FC<{ content: UserContent }> = ({content: {content: {text}}}) => {
  return <div className="mb-2 self-end rounded-2xl bg-amber-400 px-3 py-1 max-w-[40ch]">
    <div className="overflow-wrap-all">{text}</div>
  </div>
}

const ServerTextMessage: FC<{ content: TextContent | InProgressContent, }> = ({content}) => {
  const renderLoading = () => <div className="max-w-[40ch] bg-neutral-200 rounded-2xl"></div>
  const renderContent = () => <div className="break-words"
                                   dangerouslySetInnerHTML={{__html: content.content!.text ?? ''}}></div>

  return <div className={clsx("mb-2 rounded-2xl bg-neutral-200 px-3 py-1 max-w-[40ch]", {
    'animate-pulse min-h-8': content.type == 'in-progress'
  })}>
    {content.type == 'in-progress' ? renderLoading() : renderContent()}
  </div>
}

const ServerErrorMessage: FC<{ content: ErrorContent }> = ({content}) => {
  const {_} = useLingui();
  return <div className="mb-2 self-start rounded-2xl bg-danger-100 text-danger-700 px-3 py-1 max-w-[40ch]">
    <div className="break-words"
         dangerouslySetInnerHTML={{__html: content.content?.text ?? _(msg`Unexpected error happened.`)}}></div>
  </div>
}

const ServerCardMessage: FC<{
  content: CardContent | InProgressContent,
}> = ({content}) => {
  const openPlayer = useSetAtom(isPlayerVisibleAtom);
  const setActiveAudio = useSetAtom(activeAudioAtom);
  const activeContentId = useAtomValue(activeContentIdAtom);

  const renderInProgress = () => <>
    <CardBody className="overflow-hidden rounded-[0.8rem] grid grid-rows-1 grid-cols-1">
      <div className="row-start-1 col-start-1 self-center justify-self-center text-xl z-10 text-center">
        <CyclingLoader additionalTexts={content.content?.text}/>
      </div>
      <div className="row-start-1 col-start-1 animate-pulse blur">
        <div className="mb-4">
          <div className="w-full h-auto rounded-2xl aspect-video bg-neutral-200 "/>
        </div>
        <div className="h-6 w-full mb-2 rounded-2xl  bg-neutral-200"/>
        <div className="h-6 w-3/5 mb-4 rounded-2xl bg-neutral-200"/>

        <div className="h-4 w-full mb-2 rounded-2xl bg-neutral-200"/>
        <div className="h-4 w-4/5 rounded-2xl bg-neutral-200"/>
      </div>
    </CardBody>
  </>

  const playAudio = (contentId: string, content: ProcessedCard) => {
    openPlayer(true);
    setActiveAudio({
      id: contentId,
      title: content.title,
      subtitle: content.subtitle,
      audioDuration: content.audio!.duration,
      audioUrl: content.audio!.url
    });
  };

  const share = async (contentId: string, content: ProcessedCard) => {
    await setDoc(doc(getFirestore(), `share/${contentId}`), content);

    try {
      await navigator.share({
        title: content.title,
        url: `https://${window.location.host}/share?cid=${contentId}`
      })
    } catch (e) {
      await navigator.clipboard.writeText(`https://${window.location.host}/share?cid=${contentId}`);
    }
  }

  const renderPlayButton = (contentId: string, content: ProcessedCard) => {
    if (!content.audio) {
      return null;
    }

    if (contentId == activeContentId) {
      return <div className="text-small text-nowrap whitespace-nowrap"><Trans>Is playing</Trans></div>;
    }

    return <Button className="bg-amber-400" variant="flat" size="sm" onClick={() => playAudio(contentId, content)}>
      <Trans>Listen</Trans> ({formatTime(content.audio.duration)})
    </Button>;
  }

  const renderCardContent = (contentId: string, {content, meta}: CardContent) => {
    let listenedPercentage = 0;
    if (meta?.listenPosition && content.audio) {
      listenedPercentage = Math.floor(meta.listenPosition / content.audio.duration * 100);
    }
    return <>
      <CardBody className="overflow-hidden rounded-[0.8rem] pb-0">
        {content.image && <Image className="object-cover aspect-video mb-2" isBlurred src={content.image}/>}
        {content.title && <h1 className="text-large font-medium mt-2">{content.title}</h1>}
        {content.subtitle && <h2 className="text-small mb-1">{content.subtitle}</h2>}
        <div className="card-content" dangerouslySetInnerHTML={{__html: content.text}}/>
      </CardBody>
      <CardFooter className="justify-between gap-2-2 relative overflow-hidden">
        {content.url ? <a className="text-small text-foreground/60 truncate" href={content.url} target="_blank"
                          rel="nofollow" title={content.url}>{content.url}</a> : <div/>}
        <div className="flex gap-2 items-center self-end">
          <Button variant="light" size="sm" onClick={() => share(contentId, content)}><Trans>Share</Trans></Button>
          {renderPlayButton(contentId, content)}
        </div>
        <div className="absolute bottom-0 left-0 h-1 bg-amber-400 rounded-2xl transition-width" style={{width: `${listenedPercentage}%`}}></div>
      </CardFooter>
    </>
  }

  return <Card shadow="sm" classNames={{
    base: 'm-2 my-2 max-w-[65ch] overflow-visible'
  }}>
    {content.type === 'in-progress' ? renderInProgress() : renderCardContent(content.id, content)}
  </Card>
}
