import {useCallback, useEffect, useMemo, useRef, useState} from "react";

export interface useMediaProps {
  src: string,
  playbackRate: number,
  onPlay?: () => void,
  onPause?: () => void,
  onEnd?: () => void,
  onTimeUpdate?: (time: number) => void
}

export const useMedia = ({src, playbackRate, onPlay, onPause, onEnd, onTimeUpdate}: useMediaProps) => {
  const audioRef = useRef<HTMLAudioElement>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [error, setError] = useState<Error>();
  const remaining = useMemo(() => Math.max(0, duration - currentTime),
    [currentTime, duration]);
  const updateInterval = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if(!src) {
      return;
    }

    const audio = new Audio(src);
    audio.load()
    audio.playbackRate = playbackRate;
    audioRef.current = audio;


    const canPlayHandler = () => {
      setDuration(Math.floor(audio.duration));
      setIsLoaded(true);
      audio.play().catch((e) => setError(e));
    };

    const playHandler = () => {
      setIsPlaying(true);
      onPlay?.();
      updateInterval.current = setInterval(() => {
        setCurrentTime(audio.currentTime);
      }, 500);
    };

    const pauseHandler = () => {
      setIsPlaying(false);
      onPause?.();
      if (updateInterval.current) {
        clearInterval(updateInterval.current);
        updateInterval.current = undefined;
      }
    };

    const endedHandler = () => {
      setIsPlaying(false);
      setCurrentTime(0);
      onEnd?.();
      if (updateInterval.current !== null) {
        clearInterval(updateInterval.current);
        updateInterval.current = undefined;
      }
    }

    const errorHandler = () => {
      setError(new Error('An error occurred while playing the audio.'));
    };

    audio.addEventListener('canplay', canPlayHandler);
    audio.addEventListener('play', playHandler);
    audio.addEventListener('pause', pauseHandler);
    audio.addEventListener('ended', endedHandler);
    audio.addEventListener('error', errorHandler);

    return () => {
      setIsPlaying(false);
      setCurrentTime(0);
      if (updateInterval.current) {
        clearInterval(updateInterval.current)
      }
      audio.removeEventListener('canplay', canPlayHandler);
      audio.removeEventListener('play', playHandler);
      audio.removeEventListener('pause', pauseHandler);
      audio.removeEventListener('ended', endedHandler);
      audio.removeEventListener('error', errorHandler);
      audio.src = '';
    };
  }, [src]);

  useEffect(() => {
    onTimeUpdate?.(currentTime);
  }, [currentTime, onTimeUpdate]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  const play = useCallback(() => {
    const audio = audioRef.current;
    if (audio && !isPlaying) {
      audio.play().catch((err) => setError(err));
    }
  }, [isPlaying]);

  const pause = useCallback(() => {
    const audio = audioRef.current;
    if (audio && isPlaying) {
      audio.pause();
    }
  }, [isPlaying]);

  const togglePlayPause = useCallback(() => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  }, [isPlaying, play, pause]);

  const seek = useCallback(
    (time: number) => {
      const audio = audioRef.current;
      if (audio) {
        audio.currentTime = time;
        setCurrentTime(time);
      }
    },
    []
  );

  const seekBackward = useCallback(
    (seconds: number) => {
      const audio = audioRef.current;
      if (audio) {
        audio.currentTime = Math.max(0, audio.currentTime - seconds);
        setCurrentTime(audio.currentTime);
      }
    },
    []
  );

  const seekForward = useCallback(
    (seconds: number) => {
      const audio = audioRef.current;
      if (audio) {
        audio.currentTime = Math.min(duration, audio.currentTime + seconds);
        setCurrentTime(audio.currentTime);
      }
    },
    [duration]
  );

  return {
    isPlaying,
    isLoaded,
    duration,
    currentTime,
    remaining,
    error,
    play,
    pause,
    togglePlayPause,
    seek,
    seekBackward,
    seekForward,
  };
};