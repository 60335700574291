export const APP_NAME = "QuickYou";
export const EMAILSENT_KEY = "EMAILSENT_KEY";

export const playbackRates = [{
  value: 1,
  label: '1x'
}, {
  value: 1.25,
  label: '1.25x'
}, {
  value: 1.5,
  label: '1.5x'
}, {
  value: 1.75,
  label: '1.75x'
}, {
  value: 2,
  label: '2x'
}, {
  value: 2.5,
  label: '2.5x'
}];

export const nop = () => {};