import {useCallback, useEffect, useLayoutEffect} from "react";
import {useLoaderData, useParams} from "react-router-dom";
import {useAtomValue} from "jotai";
import {activeInstanceIdAtom, appHeaderAtom, isAuthenticatedAtom, userIdAtom} from "../store.ts";
import {AppMessageBox} from "../components/app-message-box.tsx";
import {addDoc, collection, getFirestore, Timestamp} from "firebase/firestore";
import {useAppContent} from "../hooks/use-app-content.ts";
import {useSetAtom} from "jotai/index";
import {AppInstallation} from "../lib/types.ts";
import {useLingui} from "@lingui/react";
import {AppContent} from "../components/app-content.tsx";
import {useScrollToBottom} from "../hooks/use-scroll-to-bottom.ts";

export function InstalledApp() {
  const isAuthenticated = useAtomValue(isAuthenticatedAtom);
  const {handle} = useParams();
  const userId = useAtomValue(userIdAtom)!;
  const loaderData = useLoaderData() as AppInstallation;
  const content = useAppContent(isAuthenticated, loaderData.id!, userId)
  const updateHeader = useSetAtom(appHeaderAtom);
  const updateActiveInstanceId = useSetAtom(activeInstanceIdAtom);

  const {i18n} = useLingui();

  useLayoutEffect(() => {
    updateHeader({
      title: loaderData?.title,
      backButtonVisible: true
    });
    updateActiveInstanceId(loaderData.id);
  }, [handle]);

  const onNewMessage = useCallback(async (msg: string) => {
    await addDoc(collection(getFirestore(), `instances/${loaderData.id!}/content`), {
      type: 'user',
      timestamp: Timestamp.now(),
      content: {
        text: msg,
      }
    });
  }, [userId, handle]);

  useEffect(() => {
    if (content?.size !== 0) {
      return;
    }

  }, [content]);

  const scrollToBottomRef = useScrollToBottom(content);

  const renderMessages = () => {
    if (!content) {
      return <>Loading</>;
    }

    if (content.size === 0) {
      return <h1>Empty</h1>
    }

    return Array.from(content).map(group => <>
      <div className="text-center sticky top-0 my-2 z-20" key={group[0]}><span
        className="shadow-sm bg-neutral-50 px-4 py-2 rounded-2xl">{i18n.date(group[0], {dateStyle: 'long'})}</span>
      </div>

      {group[1].map(c => <AppContent key={c.id} content={c}/>)}
    </>)
  }

  return <div className="flex flex-col flex-1 overflow-hidden">
    <div ref={scrollToBottomRef} className="h-full p-4 overflow-y-auto flex flex-col gap-1">
      {renderMessages()}
    </div>

    <AppMessageBox isInteractive={loaderData.interactive && isAuthenticated} onMessageSubmitted={onNewMessage}/>
  </div>
}

