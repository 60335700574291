import {FC} from "react";
import qyLogo from "../assets/qy.svg";
import {Image} from "@nextui-org/react";

const AppLogo: FC<{ src?: string }> = ({src}) => {
  let channelLogo = src;
  if (!channelLogo) {
    channelLogo = qyLogo;
  }

  return <Image className="bg-neutral-400 rounded-full shadow" src={channelLogo} width={56} height={56} alt=""/>
}

export {AppLogo};