import {Button, Card, CardBody, Slider} from "@nextui-org/react";
import {Dismiss16Regular, SkipBack1024Regular, SkipForward1024Regular,} from "@fluentui/react-icons";
import {PlayerButton} from "./player-button.tsx";
import {PlaybackSpeedButton} from "./playback-speed-button.tsx";
import {useAtom, useAtomValue} from "jotai";
import {PlayPauseIcon} from "../icons/play-pause-icon.tsx";
import {activeAudioAtom, activeInstanceIdAtom, audioSpeedAtom, isPlayerVisibleAtom, userIdAtom} from "../../store.ts";
import {formatTime} from "../../common/utils.ts";
import {RESET} from "jotai/utils";
import {Trans} from "@lingui/macro";
import {useSetAtom} from "jotai/index";
import {useMedia} from "../../hooks/use-media.tsx";
import posthog from "posthog-js";
import {useMediaSession} from "../../hooks/use-media-session.ts";
import {useCallback, useEffect, useRef} from "react";
import {doc, getDoc, getFirestore, setDoc} from "firebase/firestore";
import {useParams} from "react-router-dom";

const updateMetadata = (instanceId: string | undefined, contentId: string, metadata: {
  listenPosition: number,
  listened?: boolean
}) => {
  if(!instanceId) {
    return Promise.resolve();
  }

  return setDoc(doc(getFirestore(), `instances/${instanceId}/content/${contentId}`), {
    meta: metadata
  }, {merge: true});
}

export function Player() {
  const setIsOpen = useSetAtom(isPlayerVisibleAtom);
  const [activeAudio, setActiveAudio] = useAtom(activeAudioAtom);
  const audioSpeed = useAtomValue(audioSpeedAtom);
  const {handle} = useParams();
  const userId = useAtomValue(userIdAtom)!;
  const timeRef = useRef<number>(0);
  const activeInstanceId = useAtomValue(activeInstanceIdAtom);

  const onEnd = useCallback(async () => {
    if (!activeAudio) {
      return;
    }
    await updateMetadata(activeInstanceId, activeAudio!.id, {
      listenPosition: 0,
      listened: true
    });
  }, [activeAudio, handle, userId]);

  const timeHandler = useCallback(async (time: number) => {
    if (!activeAudio) {
      return;
    }
    const diff = Math.floor(time - timeRef.current);
    if (Math.abs(diff) >= 10) {

      await updateMetadata(activeInstanceId, activeAudio!.id, {
        listenPosition: time
      });
      timeRef.current = time;
    }
  }, [activeAudio, handle, userId])

  const {
    currentTime,
    duration,
    remaining,
    isLoaded,
    isPlaying,
    pause,
    play,
    seek,
    seekBackward,
    seekForward,
    togglePlayPause,
  } = useMedia({
    src: activeAudio?.audioUrl ?? '', playbackRate: audioSpeed ?? 1, onEnd, onTimeUpdate: timeHandler
  });

  useEffect(() => {
    if (!activeAudio) {
      return;
    }
    getDoc(doc(getFirestore(), `instances/${activeInstanceId}/content/${activeAudio!.id}`)).then(snapshot => {
      const currentTime = snapshot.get('meta.listenPosition');
      if (currentTime) {
        seek(currentTime);
      }
    })
  }, [activeAudio, handle, seek, userId]);

  useMediaSession(activeAudio!, play, pause, () => {
    setActiveAudio(RESET);
    setIsOpen(false);
  }, seek, seekBackward, seekForward);

  return (
    <Card isBlurred classNames={{
      base: 'rounded-none sm:rounded-2xl shadow-none sm:shadow-medium mx-4 my-4'
    }}>
      <CardBody className="gap-2">
        <div className="flex gap-1 justify-between ">
          <h3 className="flex-1 font-sm font-semibold  text-center line-clamp-2">{activeAudio?.title ??
              <Trans>No title</Trans>}</h3>
          <Button isIconOnly variant="light" size="sm" onClick={async () => {
            setActiveAudio(RESET);
            setIsOpen(false);

            posthog.capture('Player close');
          }}><Dismiss16Regular/></Button>
        </div>

        <Slider color="foreground"
                isDisabled={!isLoaded}
                minValue={0}
                maxValue={duration}
                value={currentTime}
                aria-label="time"
                onChange={value => seek(Array.isArray(value) ? value[0] : value)}
        ></Slider>

        <div className="flex justify-between items-center">
          <span className="text-sm">{formatTime(currentTime)}</span>

          <span className="text-sm text-foreground/50">{isLoaded ? formatTime(remaining) :
            <div className="animate-pulse w-12 h-4 rounded-2xl aspect-video bg-neutral-200 "/>}</span>
        </div>

        <div className="flex items-center justify-center gap-3">
          <PlayerButton isDisabled={!isLoaded} icon={<SkipBack1024Regular/>} onClick={() => {
            seekBackward(10);
            posthog.capture('10 sec backward', {
              source: 'player',
              audioId: activeAudio!.id
            });
          }}/>
          <PlayerButton isDisabled={!isLoaded} icon={<PlayPauseIcon isPlaying={isPlaying}/>}
                        onClick={() => {
                          const event = isPlaying ? 'Pause audio' : 'Play audio'
                          posthog.capture(event, {
                            source: 'player',
                            audioId: activeAudio!.id,
                          });

                          togglePlayPause();
                        }}/>
          <PlayerButton isDisabled={!isLoaded} icon={<SkipForward1024Regular/>} onClick={() => {
            seekForward(10);
            posthog.capture('10 sec forward', {
              source: 'player',
              audioId: activeAudio!.id
            });
          }}/>
          <PlaybackSpeedButton/>
        </div>
      </CardBody>
    </Card>
  )
}

