import {AnimatePresence, motion} from "framer-motion";
import {FC, useEffect, useMemo, useState} from "react";
import {useLingui} from "@lingui/react";
import {msg} from "@lingui/macro";

const CyclingLoader: FC<{ additionalTexts?: string | string[] }> = ({additionalTexts}) => {
  const [displayIndex, setDisplayIndex] = useState(0);
  const [isFirstArray, setIsFirstArray] = useState(true);
  const {_} = useLingui();

  const standardMessages = [_(msg`Processing`), _(msg`It will take a few seconds`),
    _(msg`Or maybe minutes`), _(msg`But not longer`)];
  const additionalMessages = useMemo(() => {
    if (!additionalTexts) {
      return [];
    }
    return Array.isArray(additionalTexts) ? additionalTexts : [additionalTexts]
  }, [additionalTexts]);

  useEffect(() => {
    const interval = setInterval(() => {
      // If we're currently showing the first array
      if (isFirstArray) {
        if (displayIndex < standardMessages.length - 1) {
          // Continue through the first array
          setDisplayIndex(displayIndex + 1);
        } else if (additionalMessages && additionalMessages.length > 0) {
          // Switch to the second array when the first is done, if the second array exists and is not empty
          setIsFirstArray(false);
          setDisplayIndex(0);
        }
        // If there's no second array provided, do nothing. This will stop at the last item of the first array.
      } else {
        // Loop through the second array indefinitely (this block will only be reached if additionalTexts is provided and not empty)
        setDisplayIndex((displayIndex + 1) % additionalMessages.length);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [displayIndex, isFirstArray, additionalMessages]);

  // Determine which array and which item to display
  const currentItem = isFirstArray ? standardMessages[displayIndex] : additionalMessages ? additionalMessages[displayIndex] : standardMessages[standardMessages.length - 1];

  return (
    <div>
      <AnimatePresence mode="wait">
        <motion.div
          key={currentItem}
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          transition={{duration: 0.20}}
        >
          {currentItem}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default CyclingLoader;