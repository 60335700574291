import {FC, FormEvent, useMemo, useState} from "react";
import {Button, Input} from "@nextui-org/react";
import {ArrowUp20Filled} from "@fluentui/react-icons";

type AppMessageBoxProps = {
  isInteractive: boolean;
  onMessageSubmitted: (message: string) => void
}
const AppMessageBox: FC<AppMessageBoxProps> = ({isInteractive, onMessageSubmitted}) => {
  const [newMessage, setNewMessage] = useState("");
  const canSubmitNewMessage = useMemo(() => {
    return newMessage.trim() !== "";
  }, [newMessage]);

  if (!isInteractive) {
    return null;
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!canSubmitNewMessage) {
      return;
    }

    onMessageSubmitted(newMessage);

    setNewMessage('');
  };

  return <div>
    <form className="flex m-4 gap-3 items-end" onSubmit={handleSubmit}>
      <Input
        type="text"
        formNoValidate={true}
        autoComplete="off"
        value={newMessage}
        onValueChange={setNewMessage}
      />
      <Button isIconOnly type="submit" isDisabled={!canSubmitNewMessage}>
        <ArrowUp20Filled/>
      </Button>
    </form>
  </div>;
}

export {AppMessageBox}