import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import {AppLayout, TwoPaneAppLayout} from "./components/app-layout.tsx";
import {SignIn} from "./routes/sign-in.tsx";
import {InstalledApp} from "./routes/installed-app.tsx";
import {InstalledApps} from "./routes/installed-apps.tsx";
import {FC, ReactElement} from "react";
import {useUser} from "./hooks/firebase.ts";
import {NextUIProvider} from "@nextui-org/react";
import {collection, getDocs, getFirestore, Query, query, where} from "firebase/firestore";
import {useTwoPaneLayout} from "./hooks/use-two-pane-layout.ts";

const RequireUnauthenticated: FC<{ children: ReactElement }> = ({children}) => {
  const {isAuthenticated} = useUser();

  if (isAuthenticated) {
    return <Navigate to="/" replace/>;
  }

  return children;
}

export function App() {
  const isTwoPane = useTwoPaneLayout();
  const user = useUser();
  const router = createBrowserRouter([
    {
      path: "/",
      element: isTwoPane ? <TwoPaneAppLayout/> : <AppLayout/>,
      errorElement: <h5>Oops</h5>,
      children: [
        {index: true, element: <Navigate to="/apps" replace/>},
        {path: '/apps', element: isTwoPane ? null : <InstalledApps/>},
        {
          path: '/apps/:handle', element: <InstalledApp/>, loader: async ({params}) => {
            let q: Query;

            if (user.isAuthenticated) {
              q = query(collection(getFirestore(), `instances`),
                where('handle', '==', params.handle),
                where('userId', 'in', [user.user?.uid, '']))
            } else {
              q = query(collection(getFirestore(), `instances`),
                where('handle', '==', params.handle))
            }

            const docs = await getDocs(q);
            return {
              id: docs.docs[0].id
              , ...docs.docs[0].data()
            };
          }
        }
      ]
    },
    {
      path: '/sign-in',
      element:
        <RequireUnauthenticated>
          <SignIn/>
        </RequireUnauthenticated>
    }
  ]);

  return <NextUIProvider className="flex flex-col h-full">
    <RouterProvider router={router}/>
  </NextUIProvider>;
}