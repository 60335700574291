import React from 'react'
import ReactDOM from 'react-dom/client'
import {i18n} from "@lingui/core";
import {detect, fromNavigator} from "@lingui/detect-locale";
import {I18nProvider} from '@lingui/react'

import './index.css'
import {captureException, init as sentryInit, replayIntegration, setUser} from "@sentry/react";
import posthog from "posthog-js";
import {initializeApp} from 'firebase/app';
import {connectAuthEmulator, getAuth, isSignInWithEmailLink, signInWithEmailLink} from 'firebase/auth';
import {connectFirestoreEmulator, doc, getFirestore, onSnapshot} from 'firebase/firestore';
import {App} from "./app.tsx";
import {EMAILSENT_KEY} from "./common/constants.ts";
import {createStore, Provider} from 'jotai';
import {firebaseAtom, isAuthenticatedAtom, UserProfile, userProfileAtom} from "./store.ts";

sentryInit({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost"],
  environment: import.meta.env.MODE,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  autocapture: false,
});

const defaultLanguage = 'en';
let language: string = defaultLanguage;
try {
  const languageDetected = detect(fromNavigator(), () => defaultLanguage)!;
  language = languageDetected.split("-")[0];
} catch (e) {
  captureException(e, {data: 'Failed to detect language on startup, fallback to default'});
}

async function loadTranslations() {
  const {messages} = await import(`./locales/${language}.po`);
  i18n.loadAndActivate({locale: language!, messages});
}

const firebase = initializeApp({
  apiKey: import.meta.env.VITE_APIKEY,
  authDomain: import.meta.env.VITE_AUTHDOMAIN,
  projectId: import.meta.env.VITE_PROJECTID,
  storageBucket: import.meta.env.VITE_STORAGEBUCKET,
  messagingSenderId: import.meta.env.VITE_MESSAGINGSENDERID,
  appId: import.meta.env.VITE_APPID,
  measurementId: import.meta.env.VITE_MEASUREMENTID,
});

const auth = getAuth(firebase);
auth.languageCode = language;

if (import.meta.env.DEV) {
  connectAuthEmulator(auth, "http://localhost:9099", {disableWarnings: true});
  connectFirestoreEmulator(getFirestore(firebase), "localhost", 8080);
}

if (isSignInWithEmailLink(auth, window.location.href)) {
  let email = window.localStorage.getItem(EMAILSENT_KEY);
  if (!email) {
    email = window.prompt("Please provide your email for confirmation");
  }
  if (!email) {
    window.location.href = "/sign-in";
  }

  const result = await signInWithEmailLink(auth, email!, window.location.href);
  window.localStorage.removeItem(EMAILSENT_KEY);
  if (result.user) {
    window.location.href = "/";
  }
}

await Promise.all([auth.authStateReady(), loadTranslations()]);

const qyStore = createStore();
qyStore.set(firebaseAtom, firebase!);

auth.onAuthStateChanged((user) => {
  qyStore.set(isAuthenticatedAtom, user !== null);
  if (user == null) {
    qyStore.set(userProfileAtom, null);
  } else {
    onSnapshot(doc(getFirestore(), 'users', user.uid), snapshot => {
      const profile = snapshot.data() as UserProfile;

      if (profile) {
        qyStore.set(userProfileAtom, {
          userId: user.uid,
          email: user.email!,
          installedAppIds: profile.installedAppIds,
          avatar: user.photoURL,
          language: profile.language,
          audioSpeed: profile.audioSpeed
        } as UserProfile)
      }
    }, captureException)
  }
});

if (auth.currentUser) {
  posthog.identify(auth.currentUser.uid, {
    email: auth.currentUser.email!
  });
  setUser({
    email: auth.currentUser.email!,
    id: auth.currentUser.uid
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <I18nProvider i18n={i18n}>
      <Provider store={qyStore}>
        <App/>
      </Provider>
    </I18nProvider>
  </React.StrictMode>,
)
